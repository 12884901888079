<button style="border-width: 0px; background-color: transparent;" [matMenuTriggerFor]="columnMenu">
    <ng-content></ng-content>
</button>
<mat-menu #columnMenu="matMenu">
    <div class="digby-table-dragable-container" cdkDropList dkDropListLockAxis="y" cdkDropListOrientation="vertical">
        <button digby-table-column-clear id="col-clear-button" style="border-width: 0px !important;" (click)="onResetColumns()">
          <mat-icon class="primary-icon"  style="padding-left: 0px !important;">clear</mat-icon>
        </button>
        <label class="form-check-label" for="col-clear-button">Reset Columns</label>
        <div class="dragable-row"
          *ngFor="let column of selectedColumnInfo; let i = index"
          cdkDrag
          [cdkDragData]="{columnIndex: i, columnTitle: column.id}"
          (cdkDragDropped)="columnMenuDropped($event)">
            <mat-icon cdkDragHandle>drag_handle</mat-icon>
            <mat-checkbox
                [disabled]="column.preventBeingHidden"
                [checked]="!column.hidden"
                [matTooltip]="column.description"
                matTooltipPosition="left"
                matTooltipClass="digby-table-tooltip"
                (click)="$event.stopPropagation();"
                (change)="toggleSelectedColumn(column.id)">
                {{ column.name }}
            </mat-checkbox>

            <mat-card class="digby-table-drag-preview" *cdkDragPreview>
                <mat-icon>drag_handle</mat-icon>
                <mat-checkbox [checked]="!column.hidden">
                    {{ column.name }}
                </mat-checkbox>
            </mat-card>
        </div>
        <div *ngFor="let section of sections">
          <div class="digby-table-section-header" *ngIf="section !== 'Selection'">
            {{section}}
          </div>
          <div class="digby-table-undragable dragable-row" *ngFor="let column of classifiedColumnInfo[section]">
            <mat-checkbox
                [hidden]="!column.hidden"
                [matTooltip]="column.description"
                matTooltipPosition="left"
                matTooltipClass="digby-table-tooltip"
                (click)="$event.stopPropagation();"
                (change)="toggleSelectedColumn(column.id)">
                {{ column.name }}
            </mat-checkbox>
          </div>
        </div>
    </div>
</mat-menu>
