<div class="row">
  <div  class="spinner-border" role="status" *ngIf="dataSource.loading$ | async">
    <span class="sr-only">Loading...</span>
  </div>

  <div class="alert alert-danger" *ngIf="dataSource.error$ | async">
    <div class="row">
      <h4>Error loading table</h4>
      <p>{{dataSource.error$ | async}}</p>
    </div>
  </div>
</div>

<div class="row">
  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex;">
    <span class="header-text">Quick search:&nbsp;</span>
    <input class="searchBox" #searchBox>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button style="border-width: 0px; background-color: transparent;" (click)="clearSelection()">
      <mat-icon class="primary-icon"  style="">delete_sweep</mat-icon>
      <span class="header-text">Clear filters</span>
    </button>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button digby-table-column-sorter
       [(columns)]="displayedColumns"
       [columnInfo]="allColumns"
       [saveName]="'gen-gene-table'"
       (columnsChange)="updateColumnData($event)" >
       <mat-icon class="primary-icon"  style="">view_column</mat-icon>
      <span class="header-text">Arrange Columns</span>
    </button>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex; padding-left: 0px !important;">
    <button style="border-width: 0px; background-color: transparent;" [matMenuTriggerFor]="downloadMenu" id="downloadbutton">
      <mat-icon class="primary-icon"  style="">cloud_download</mat-icon>
    </button>
    <label class="form-check-label" for="downloadbutton">Download</label>
  </div>

  <div class="col-auto" style="align-items: center; justify-content: flex-start; display: flex;" *ngIf="samplesSelected">
    <div class="form-check">
      <input class="form-check-input" type="checkbox" id="chk-selected-items" style="margin-top: .1rem;"
        [(ngModel)]="isSelectedGenesChecked"
        (change)="(onSelectedIdsChange(isSelectedGenesChecked))"
      >
      <label class="form-check-label" for="chk-selected-items">
        Only alleles from selected samples
      </label>
    </div>
  </div>

  <div class="col-auto ml-auto">
    <mat-paginator [length]="dataSource?.totalItems" [pageSize]="25" [pageSizeOptions]="[25, 50, 100, 250]"></mat-paginator>
  </div>
</div>

<div class="row">
  <div class="col">
    <mat-table [dataSource]="dataSource">

      <ng-container *ngFor="let col of allColumns">
        <ng-container *ngIf="(col.type === 'string')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <div *ngIf="col.id === 'name'" >
                  <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.TEXT_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
                </div>
                <div *ngIf="col.id === 'type'" >
                  <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.TEXT_MODE" [setFilter$] = "setTypeFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
                </div>
                <div *ngIf="col.id !== 'name' && col.id !== 'type'" >
                  <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.TEXT_MODE" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
                </div>
              </mat-header-cell>
                <div *ngIf="col.id === 'sequence' || col.id === 'gapped_sequence'" >
                  <mat-cell class="{{col.size}} mat-ellipsis" *matCellDef="let item"  (click)="onSequenceClick(item)">
                      {{item[col.id]}}
                  </mat-cell>
                </div>
                <div *ngIf="!(col.id === 'sequence' || col.id ==='gapped_sequence')">
                  <mat-cell class="{{col.size}}" *matCellDef="let item">
                    {{item[col.id]}}
                  </mat-cell>
                </div>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(col.type === 'integer' || col.type === 'number')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.NUMBER_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
              </mat-header-cell>
              <mat-cell class="{{col.size}}" *matCellDef="let item">
                <div *ngIf="col.id === 'appearances'" (click)="onAppearancesClick(item)">
                  {{item[col.id]}}
                </div>
                <div *ngIf="col.id !== 'appearances'">
                  {{item[col.id]}}
                </div>
              </mat-cell>
            </ng-container>
        </ng-container>

        <ng-container *ngIf="(col.type === 'boolean')">
            <ng-container matColumnDef={{col.id}}>
              <mat-header-cell class="{{col.size}}" *matHeaderCellDef mwlResizable [enableGhostResize]="true" (resizeEnd)="onResizeEnd($event, col.id)" [resizeEdges]="{bottom: false, right: true, top: false, left: true}">
                {{col.name}}
                <app-filter #filterComponent="menuInOtherComponent" [columnName]="col.id" [filterMode]="filterModeEnum.BOOL_MODE" [setFilter$] = "setFilter$" [clear$] = "clear$" [choices$]="dataSource.choices$" (predicateEmitter)="applyFilter($event)"></app-filter>
              </mat-header-cell>
              <mat-cell class="{{col.size}}" *matCellDef="let item">
                <i *ngIf="item[col.id]" class="fa fa-check" style="line-height: inherit;"></i>
              </mat-cell>
            </ng-container>
        </ng-container>
      </ng-container>

      <mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></mat-header-row>

      <mat-row *matRowDef="let row; columns: displayedColumns"></mat-row>

    </mat-table>
  </div>
</div>

<mat-menu #downloadMenu="matMenu" xPosition="before" class="menu-overflow-hidden">
  <ng-template matMenuContent>
    <div class="container">
      <div class="row ml-1 mr-2">
        <b>Download data for selected genes</b>
      </div>
      <div class="row ml-4 mr-2 mb-0 align-middle">
        Ungapped sequences (fasta)
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('download_gen_data', 'fasta', {type:'Ungapped sequences (fasta)'})">
            <i class="fa fa-file-text-o" style="line-height: inherit;" ></i> </button>
      </div>
      <div class="row ml-4 mr-2 mb-0 align-middle">
        Gapped sequences (fasta)
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('download_gen_data', 'fasta', {type:'Gapped sequences (fasta)'})">
            <i class="fa fa-file-text-o" style="line-height: inherit;" ></i> </button>
      </div>
      <div class="row ml-4 mr-2 mb-0 align-middle">
        Gene information (csv)
          <span>&nbsp;&nbsp;</span>
          <button class="btn btn-sm btn-solid-icon addr" (click)="sendReportRequest('download_gen_data', 'csv', {type:'Gene information (csv)'})">
            <i class="fa fa-file-text-o" style="line-height: inherit;" ></i> </button>
      </div>
    </div>
  </ng-template>
</mat-menu>
