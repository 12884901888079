<app-gene-table-selector [showGenomic] = true [showAssembly] = false [showRepseq]=true></app-gene-table-selector>
<div>
  <h2>VDJbase Reports</h2>
  <p>Please select above the species and datasets on which you would like to report. Multiple datasets from the same species can be selected. </p>

  <p>You can refine the samples against which the reports will run by using filters in the Samples windows to display just those samples that you wish to be included.</p>

  <p>To run the report, click on an icon in the Run column corresponding to the output format that you would like (e.g. pdf, on-screen, Excel)</p>

  <p><a href="assets/doc/report_documentation.pdf">Click here</a> for explanation of reports.</p>

  <ng-container *ngFor="let reportSection of reportSections">
      <h2>{{reportSection.title}}</h2>
      <mat-table [dataSource]="reportSection.source">
        <ng-container matColumnDef="title">
          <mat-header-cell *matHeaderCellDef> Name </mat-header-cell>
          <mat-cell *matCellDef="let rep"> <b>{{rep.title}}</b> </mat-cell>
        </ng-container>
        <ng-container matColumnDef="thumbnail">
          <mat-header-cell *matHeaderCellDef> Thumbnail </mat-header-cell>
          <mat-cell *matCellDef="let rep">
            <div *ngIf="rep.thumbnail">
              <img src='{{rep.thumbnail}}' height='100'>
            </div>
          </mat-cell>
        </ng-container>
        <ng-container matColumnDef="description">
          <mat-header-cell *matHeaderCellDef> Description </mat-header-cell>
          <mat-cell *matCellDef="let rep"> {{rep.description}} </mat-cell>
        </ng-container>
        <ng-container  matColumnDef="actions">
          <mat-header-cell *matHeaderCellDef> Run </mat-header-cell>
          <mat-cell *matCellDef="let rep">
            <ng-container *ngIf="rep.format.indexOf('pdf') >= 0">
              <button class="btn btn-lg btn-solid-icon" (click)="runReport(rep, 'pdf')">
                <i class="fa fa-file-pdf-o" style="line-height: inherit;"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="rep.format.indexOf('html') >= 0">
              <button class="btn btn-lg btn-solid-icon" (click)="runReport(rep, 'html')">
                <i class="fa fa-desktop" style="line-height: inherit;"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="rep.format.indexOf('xls') >= 0">
              <button class="btn btn-lg btn-solid-icon" (click)="runReport(rep, 'xls')">
                <i class="fa fa-file-excel-o" style="line-height: inherit;"></i>
              </button>
            </ng-container>
            <ng-container *ngIf="rep.format.indexOf('file') >= 0">
              <button class="btn btn-lg btn-solid-icon" (click)="runReport(rep, 'xls')">
                <i class="fa fa-save" style="line-height: inherit;"></i>
              </button>
            </ng-container>
          </mat-cell>
        </ng-container>
        <mat-header-row *matHeaderRowDef="displayedColumns"></mat-header-row>
        <mat-row *matRowDef="let row; columns: displayedColumns;"></mat-row>
        <p style="margin-bottom: 32px" *matNoDataRow>{{reportSection.empty}}</p><br><br>
      </mat-table>
  </ng-container>
</div>
