<div class="modal-header">
  <h4 class="modal-title">{{name}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <pre class="lead">
    <div style="width: 600px;margin-left: auto;margin-right: auto;" [innerHTML]=displayContent></div>
  </pre>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-outline-dark" [disabled] ="!gappedAvailable" (click)="onGappedClick($event.target)">Gapped</button>
  <button type="button" class="btn btn-outline-dark" (click)="onFastaClick($event.target)">FASTA</button>
  <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
</div>
