  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">{{ this.report }}</h4>
    <button type="button" class="close" aria-label="Close" (click)="this.activeModal.close('Cancel')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    {{ errorMessage }}
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="this.activeModal.close('OK')">OK</button>
  </div>

