<div class="row">
  <div class="col-xs-12 col-md-6 col-md-offset-3">
    <div class="alert alert-danger" *ngIf="error">
      <p>{{ error }}</p>
    </div>
    <div  class="spinner-border" role="status" *ngIf="isLoading">
      <span class="sr-only">Loading...</span>
    </div>
    <form #authForm="ngForm" (ngSubmit)="onSubmit(authForm)" *ngIf="!isLoading">
      <div class="form-group">
        <label for="userName">User Name</label>
        <input
          type="text"
          id="userName"
          class="form-control"
          ngModel
          name="userName"
          required
        />
      </div>
      <div class="form-group">
        <label for="password">Password</label>
        <input
          type="password"
          id="password"
          class="form-control"
          ngModel
          name="password"
          required
        />
      </div>
      <div class="checkbox">
        <label>
        <input
          type="checkbox"
          id="stay"
          name="stay"
          [checked] = "stay_logged"
          [(ngModel)]="stay_logged"
        />
        Stay logged in on this computer
        </label>
      </div>
      <div>
        <button
          class="btn btn-primary"
          type="submit"
          [disabled]="!authForm.valid"
        >
          Login
        </button>
      </div>
    </form>
  </div>
</div>
