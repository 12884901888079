import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sample-rep',
  templateUrl: './rep-sample.component.html',
  styleUrls: ['./rep-sample.component.scss']
})
export class RepSampleComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
