import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-gene-browser',
  templateUrl: './gene-browser.component.html',
  styleUrls: ['./gene-browser.component.scss']
})
export class GeneBrowserComponent implements OnInit {
  constructor() { }

  ngOnInit() {
  }

}
