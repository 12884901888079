<button mat-icon-button [matMenuTriggerFor]="filterMenu">
  <span>
    <mat-icon *ngIf="selectedItems.length > 0">filter_list</mat-icon>
    <mat-icon *ngIf="selectedSort==='desc'">arrow_downward</mat-icon>
    <mat-icon *ngIf="selectedSort==='asc'">arrow_upward</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
    </span>
</button>

<mat-menu #filterMenu="matMenu" xPosition="before" class="menu-overflow-hidden tf-mat-menu">
  <div class="content-centered" (click) = "$event.stopPropagation()">
    <form class="col-12" >
      <div class="row">
        <div class="col-6" style="padding-left: 0px">
          <button mat-button class="float-left" (click)="onSortAsc()">Sort Ascending</button>
        </div>
        <div class="col-6" style="text-align: right">
          <button class="float-right" mat-flat-button color="primary"  (click)="onValidation()">
            OK
          </button>
        </div>
      </div>
      <div class="row">
        <button class="float-left" mat-button (click)="onSortDesc()">Sort Descending</button>
      </div>
      <div class="row" *ngIf="selectedSort">
        <button class="float-left" mat-button  (click)="onSortClear()">Clear Sort</button>
      </div>

      <div *ngIf="selectedItems.length">
        <div class="row">
          <button class="float-left" mat-button  (click)="onClearSelection()">Clear Selection</button>
        </div>
      </div>
      <div class="row">
        <ng-multiselect-dropdown name="multi"  class="col-12"
          [class]="'filter-dropdown'"
          [settings]="dropdownSettings"
          [data]="choices"
          [placeholder]="'Select Items'"
          [(ngModel)]="selectedItems"
        >
        </ng-multiselect-dropdown>
      </div>

    </form>
  </div>
</mat-menu>
