<button mat-icon-button [matMenuTriggerFor]="filterMenu">
  <span>
    <mat-icon *ngIf="selectedOperator || (selectedItems.length > 0)">filter_list</mat-icon>
    <mat-icon *ngIf="selectedSort==='desc'">arrow_upward</mat-icon>
    <mat-icon *ngIf="selectedSort==='asc'">arrow_downward</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
    </span>
</button>

<mat-menu #filterMenu="matMenu" xPosition="before" class="menu-overflow-hidden tf-mat-menu">
  <div class="content-centered" (click) = "$event.stopPropagation()">
    <form class="col-12" >
      <div class="row">
        <div class="col-6" style="padding-left: 0px">
          <button class="float-left" mat-button (click)="onSortAsc()">Sort Ascending</button>
        </div>
        <div class="col-6" style="text-align: right">
          <button class="float-right" mat-flat-button color="primary"  (click)="onValidation()">
            OK
          </button>
        </div>
      </div>
      <div class="row">
        <button class="float-left" mat-button (click)="onSortDesc()">Sort Descending</button>
      </div>
      <div class="row" *ngIf="selectedSort">
        <button  class="float-left"mat-button  (click)="onSortClear()">Clear Sort</button>
      </div>

      <div class="row">
      <div class="dropdown-divider"></div>
      <mat-form-field class="col-12">
        <mat-select placeholder="Select comparison" [(value)]="selectedOperator">
          <mat-option *ngFor="let operator of operatorList" [value]="operator">
            {{operator.name}}
          </mat-option>
        </mat-select>
      </mat-form-field>
      </div>

      <ng-container *ngIf="selectedOperator">
        <div class="row">
          <mat-form-field class="col-12" (click) = "$event.stopPropagation()" >
            <input matInput placeholder="Numeric operand 1" [(ngModel)]="operand1Input" name="operand1Input">
          </mat-form-field>
        </div>

        <ng-container *ngIf="selectedOperator.operands === 3">
          <div class="row">
            <span class="col-12">and</span>
          </div>
          <div class="row">
            <mat-form-field class="col-12" (click) = "$event.stopPropagation()" >
              <input matInput placeholder="Numeric operand 2" [(ngModel)]="operand2Input" name="operand2Input">
            </mat-form-field>
          </div>
        </ng-container>

      <div class="row">
        <button mat-button  (click)="onClearFilter()">Clear Filter</button>
      </div>
      <div class="dropdown-divider"></div>
      </ng-container>

      <div *ngIf="selectedItems.length">
        <div class="row">
          <button mat-button  (click)="onClearSelection()">Clear Selection</button>
        </div>
      </div>

      <div>
        <ng-multiselect-dropdown name="multi"  class="col-12"
          [class]="'filter-dropdown'"
          [settings]="dropdownSettings"
          [data]="choices"
          [placeholder]="'Select Items'"
          [(ngModel)]="selectedItems"
        >
        </ng-multiselect-dropdown>
      </div>
    </form>
  </div>
</mat-menu>
