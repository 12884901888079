<div class="container">
    <h1>Content</h1>
    <p>Information provided on this site and any reports that are created here are published under a <a href="https://creativecommons.org/publicdomain/zero/1.0/legalcode">CC0 (Creative Commons 0) license</a>.</p>
    <h1>Source Code</h1>
    <p>Source Code is available at the following links:</p>
    <ul>
      <li><a href="https://github.com/williamdlees/digby">Website front-end</a></li>
      <li><a href="https://github.com/williamdlees/digby_backend">Website back-end</a></li>
      <li><a href="https://bitbucket.org/yaarilab/vdjbasevis">Reporting package</a></li>
      <li><a href="https://bitbucket.org/yaarilab/processpipeline">Analysis pipeline</a></li>
    </ul>
</div>
