<div class="container-fluid">
  <div class="row">
    <div class="col-md-12">
      <app-header></app-header>
    </div>
  </div>

  <div class="row">
    <div class="col-md-12">
        <router-outlet></router-outlet>
    </div>
  </div>
</div>
