
<div class="container">
  <h2>Video Library</h2>

  <div class="row">
    <div class="col-sm">
      <p class="ml1">Exploring AIRR-seq data sets with the new user interface</p>
    </div>
  </div>
  <div class="row">
      <video width="40%" height="40%" controls>
        <source src="./assets/img/new_ui.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
  </div>

  <div class="row" style="margin-bottom: 20px;">
  </div>

  <div class="row">
    <div class="col-sm">
      <p>Genomic data in VDJbase</p>
    </div>
  </div>
  <div class="row">
      <video width="40%" height="40%" controls>
        <source src="/assets/img/genomic.mp4" type="video/mp4">
        Your browser does not support the video tag.
      </video>
    </div>
</div>
