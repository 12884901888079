<button mat-icon-button [matMenuTriggerFor]="filterMenu">
  <span>
    <mat-icon *ngIf="selectedOperator || (selectedItems.length > 0)">filter_list</mat-icon>
    <mat-icon *ngIf="selectedSort==='desc'">arrow_downward</mat-icon>
    <mat-icon *ngIf="selectedSort==='asc'">arrow_upward</mat-icon>
    <mat-icon>arrow_drop_down</mat-icon>
    </span>
</button>

<mat-menu #filterMenu="matMenu" xPosition="before" class="menu-overflow-hidden tf-mat-menu">
  <div class="container content-centered" (click) = "$event.stopPropagation()">
    <form>
      <div class="row">
        <div *ngIf="showSort">
          <div class="col-8" style="padding-left: 0px; padding-right: 16px">
            <button class="float-left" mat-button (click)="onSortAsc()">Sort Ascending</button>
          </div>
        </div>
        <div class="col-4 ml-auto">
          <button class="float-right"  style="min-width: 0px" mat-flat-button color="primary"  (click)="onValidation()">
            OK
          </button>
        </div>
      </div>
      <div *ngIf="showSort">
        <div class="row">
          <div class="col-12"  style="padding-left: 0px">
            <button class="float-left" mat-button (click)="onSortDesc()">Sort Descending</button>
          </div>
        </div>
      </div>
      <div *ngIf="selectedSort">
        <div class="row">
          <button mat-button  (click)="onSortClear()">Clear Sort</button>
        </div>
      </div>
      <div *ngIf="showTextFilter">
        <div class="row">
        <div class="dropdown-divider"></div>
        <mat-form-field class="col-12">
          <mat-select placeholder="Select Filter" [(value)]="selectedOperator" [compareWith]="compareFn">
            <mat-option *ngFor="let operator of operatorList" [value]="operator">
              {{operator.name}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>

      <ng-container *ngIf="selectedOperator">
        <div class="row">
          <mat-form-field class="col-12" (click) = "$event.stopPropagation()" >
            <input matInput placeholder="Filter value" [(ngModel)]="operand1Input" name="operand1Input">
          </mat-form-field>
        </div>

        <ng-container *ngIf="selectedOperator.operands === 3">
          <div class="row">
            <span class="col-12">and</span>
          </div>
          <div class="row">
            <mat-form-field class="col-12" (click) = "$event.stopPropagation()" >
              <input matInput placeholder="Filter value 2" [(ngModel)]="operand2Input" name="operand2Input">
            </mat-form-field>
          </div>
        </ng-container>

        <div class="row">
          <button mat-button  (click)="onClearFilter()">Clear Filter</button>
        </div>
        <div class="dropdown-divider"></div>
      </ng-container>
      </div>

      <div *ngIf="selectedItems.length">
        <div class="row">
          <button mat-button  (click)="onClearSelection()">Clear Selection</button>
        </div>
      </div>

      <div>
        <ng-multiselect-dropdown name="multi"  class="col-12"
          [class]="'filter-dropdown'"
          [settings]="dropdownSettings"
          [data]="choices"
          [placeholder]="'Select Items'"
          [(ngModel)]="selectedItems"
        >
        </ng-multiselect-dropdown>
      </div>

    </form>
  </div>
</mat-menu>
