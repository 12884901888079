import { Component, OnInit } from '@angular/core';


@Component({
  selector: 'app-rep-explore-data',
  templateUrl: './rep-explore-data.component.html',
  styleUrls: ['./rep-explore-data.component.scss']
})
export class RepExploreDataComponent implements OnInit {

  constructor(
  ) { }

  ngOnInit(): void {
  }

}
