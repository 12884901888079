import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-genetable',
  templateUrl: './gen-gene-table.component.html',
  styleUrls: ['./gen-gene-table.component.scss']
})
export class GenGeneTableComponent implements OnInit {

  ngOnInit() {
  }
}
