<ng-container>
  <div class="modal-header">
    <h3 class="modal-title">Sequence Notes - {{sequenceName}}</h3>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="container" [innerHTML]="notes | safe: 'html'"></div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-outline-dark" (click)="activeModal.close('Close click')">Close</button>
  </div>
</ng-container>

